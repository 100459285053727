export const CHANNEL_TYPES = {
  DRIBBLE: 'DRIBBLE',
  GITHUB: 'GITHUB',
  INSTAGRAM: 'INSTAGRAM',
  LINKEDIN: 'LINKEDIN',
  TELEGRAM: 'TELEGRAM',
  TWITTER: 'TWITTER',
  WEBSITE: 'WEBSITE',
  YOUTUBE: 'YOUTUBE',
  OTHER: 'OTHER',
};
